import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import TermsUsingCredit from "../components/TermsUsingCredit/TermsUsingCredit";
import video1 from "../assets/video/1.mp4";
import moneyAutumnGroshi247 from "../assets/video/money_autumn_groshi_247.mp4";
import moneyBeforeSalary from "../assets/stock/money_before_salary.png";
import blogImg1 from "../assets/blog_img_1.png";
import blogImg2 from "../assets/blog_img_2.png";
import blogImg3 from "../assets/blog_img_3.png";
import blogImg4 from "../assets/blog_img_4.png";
import blogImg5 from "../assets/blog_img_5.png";
import blogImg6 from "../assets/blog_img_6.png";
import ScrollLink from "../lib/helpers/ScrollLink";

const Stock = () => {
    return (
        <div className="stock">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="stock" />
                    <div className="blog__wrapper">
                        <div className="title">Акції</div>
                        <div className="blog__list">
                            <div className="blog__item">
                                <div className="blog__video">
                                    <video controls loop autoPlay>
                                        <source src={moneyAutumnGroshi247} type="video/mp4" />
                                    </video>
                                </div>
                                <div className="blog__title">
                                    ГРОШОВА ОСІНЬ -50%
                                </div>
                                <div className="blog__desc">
                                    Акція -50% за промокодом N50
                                </div>
                                <ScrollLink to="/stock/money-autumn" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={moneyBeforeSalary} alt="Money Before Salary" />
                                </div>
                                <div className="blog__title">
                                    Наші переваги
                                </div>
                                <div className="blog__desc">
                                    Гроші онлайн – до зарплати не чекай!
                                </div>
                                <ScrollLink to="/stock/money-before-salary" className="btn btn__pink">
                                    Читати далі
                                </ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__video">
                                    <video controls loop autoPlay>
                                        <source src={video1} type="video/mp4" />
                                    </video>
                                </div>
                                <div className="blog__title">
                                    Потрібні гроші до 1 вересня?
                                </div>
                                <div className="blog__desc">
                                    Допоможемо зібрати дитину в школу
                                </div>
                                <ScrollLink to="/stock/need-money" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            {/* <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg4} alt="Blog img 4" />
                                </div>
                                <div className="blog__title">
                                    Знижено ставку
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg5} alt="Blog img 5" />
                                </div>
                                <div className="blog__title">
                                    Акція до Дня Незалежності
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg6} alt="Blog img 6" />
                                </div>
                                <div className="blog__title">
                                    Знижка 10%
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg1} alt="Blog img 1" />
                                </div>
                                <div className="blog__title">
                                    Знижено ставку
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg2} alt="Blog img 2" />
                                </div>
                                <div className="blog__title">
                                    Акція до Дня Незалежності
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg3} alt="Blog img 3" />
                                </div>
                                <div className="blog__title">
                                    Знижка 10%
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div> */}
                        </div>
                        {/* <div className="blog__btn">
                            <button className="btn btn__green">Більше статей</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default Stock;